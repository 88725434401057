











































import BaseGrid from '@/components/base/grid/BaseGrid.vue';
import BaseGridCheckbox from '@/components/base/grid/BaseGridCheckbox.vue';
import BaseGridFooter from '@/components/base/grid/BaseGridFooter.vue';
import BaseGridHeader from '@/components/base/grid/BaseGridHeader.vue';
import Vue, { VueConstructor } from 'vue';
import { isUndefined as _isUndefined } from 'lodash';
import { utIsEmpty } from '@/utils/empty';

const req = require.context('@/assets/icons', false, /^\.\/.*\.svg$/);

export default (Vue as VueConstructor).extend({
  name: 'BaseGridList',

  components: {
    BaseGridFooter,
    BaseGridHeader,
  },

  extends: BaseGrid,

  props: {
    attributeMode: {
      type: Boolean,
      default: false,
    },

    customClass: {
      default: 'grid-list',
      type: String,
    },

    draggable: {
      default: false,
      type: Boolean,
    },

    frameworkComponents: {
      type: Object,
      default: function() {
        return {};
      },
    },

    headerCustomClass: {
      default: '',
      type: String,
    },

    headerHeading: {
      type: String,
      default: '',
    },

    loadingOverlayComponent: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      customColumnDefinition: null,

      hideHeaderRow: true,

      isBaseGridList: true,

      isBaseGridDraggable: this.draggable,
    };
  },

  methods: {
    onRowClick(event): void {
      if (
        !_isUndefined(this.columnDefinition[0].cellRendererFramework) &&
        this.columnDefinition[0].cellRendererFramework === BaseGridCheckbox
      ) {
        this.$emit('click:row', event);
      } else {
        this.$emit('click:row', event.target.getAttribute('row-id'));
      }
    },
  },

  created() {
    this.columnDefinition.forEach(
      columnDefinition => {
        if (columnDefinition.renderSvgIcons) {
          delete columnDefinition.renderSvgIcons;

          columnDefinition.cellRenderer = params => {
            if (utIsEmpty(params.data.icon)) {
              return params.value;
            } else {
              const svg = req('./' + params.data.icon + '.svg');

              return `<div class="icon-with-text"><div class="svg-icon">${svg}</div>${params.value}</div></div>`;
            }
          };
        }
      },
    );
  },
});















import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import Vue, { VueConstructor } from 'vue';
import { SELECTIONSTATE, TSelectionState } from '@/utils/states';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor).extend({
  name: 'BaseGridCheckbox',

  components: {
    BaseCheckbox,
  },

  data() {
    return {
      value: {},
    };
  },

  computed: {
    newSelectionState(): TSelectionState {
      if (this.node.selectionState === SELECTIONSTATE.UNCHECKED) {
        return SELECTIONSTATE.CHECKED;
      }

      return SELECTIONSTATE.UNCHECKED;
    },
  },

  methods: {
    selectRow(selectionState): void {
      const payload = {
        ID: this.value.ID,
        selectionState: selectionState,
      };

      this.params.context.componentParent.onRowClick(payload);
    },
  },

  beforeMount(): void {
    this.value = this.params.data.value;

    if (utIsEmpty(this.value.selectionState)) {
      this.value.selectionState = SELECTIONSTATE.UNCHECKED;
    }
  },
});
